import axios from "axios";
import { useDispatch } from "react-redux";
import { Logout, resetAllData } from "./Store/Slices/UserAuthSlice";

var url = window.location.host;
var domain = url.replace('www.', '');

var data = localStorage.getItem('owner_data');

const owner_data = (localStorage.getItem('owner_data') !== '' && localStorage.getItem('owner_data') !== undefined && localStorage.getItem('owner_data') !== 'undefined') ? JSON.parse(localStorage.getItem('owner_data')) : {};

const prod = {
    APP_URL: 'https://recycling-network.de/backend/',
    API_BASE_URL: 'https://recycling-network.de/backend/api/v1/',
    APP_NAME: 'Skip Hire Container Service',

    MAIN_OWNER_ID: (localStorage.getItem('MAIN_OWNER_ID') !== '' || localStorage.getItem('MAIN_OWNER_ID') !== undefined) ? localStorage.getItem('MAIN_OWNER_ID') : 0,

    API_LOGIN: 'user/login',
    API_LOGOUT: 'user/logout',
    API_REGISTER: 'user/register',
    API_FORGOT_PASSWORD: 'user/forgot-password',
    API_UPDATE_PROFILE: 'user/profile-update',
    API_CHANGE_PASSWORD: 'user/change-password',
    API_CONTACT_OWNER: 'user/contact-owner',
    API_CONTACT_OWNER_FOR_WEB: 'user/contact-owner-for-web',
    API_ADDRESS_LIST: 'user/address-list',
    API_ADD_ADDRESS: 'user/add-address',
    API_UPDATE_ADDRESS: 'user/update-address',
    API_GET_ORDER_NOTES_DOCUMENTS: 'user/get-order-notes-documents',
    API_GET_ORDER_LIST: 'user/get-order-list',
    API_GARBAGE_TYPE_LIST: 'user/garbage-type-list',
    API_ZIPCODE_CHECK: 'user/zipcode-check',
    API_CONTAINER_LIST: 'user/container-list',
    API_GET_BLOGS_OFFER: 'user/get-blogs-offer',
    API_GET_BLOG_DETAIL: 'user/get-blogs-detail',
    API_MAKE_PICKUP_REQUEST: 'user/make-pickup-request',
    API_MAKE_ORDER_PAYMENT: 'user/make-order-payment',
    API_MAKE_PAYPAL_PAYMENT: 'user/make-paypal-payment',

    //Material Module API
    API_MATERIAL_CATEGORY_LIST: 'user/material/categories',
    API_MATERIAL_PRODUCT_LIST: 'user/material/products',
    API_MATERIAL_MAKE_STRIPE_PAYMENT: 'user/material/make-order-payment',
    API_MATERIAL_MAKE_PAYPAL_PAYMENT: 'user/material/make-order-paypal-payment',
    API_MATERIAL_GET_ORDER_HISTORY: 'user/material/get-order-list',
    API_MATERIAL_MAKE_PICKUP_REQUEST: 'user/material/make-pickup-request',
    API_MATERIAL_GET_ORDER_NOTES_DOCUMENT: 'user/material/get-material-order-notes-documents',
    API_MATERIAL_GET_SINGLE_PRODUCT: 'user/material/get-single-product',

    API_PAYMENT_INTENT: 'user/create-payment-intent',

    API_APPLY_COUPON: 'user/apply-coupon',
    API_REMOVE_COUPON: 'user/remove-coupon',

    STRIPE_KEY: (owner_data) ? owner_data.stripe_key : "",
    STRIPE_SECRET: (owner_data) ? owner_data.stripe_secret_key : "",
    PAYPAL_CLIENT_ID: (owner_data) ? owner_data.paypal_key : "",
    PAYPAL_CLIENT_SECRET: (owner_data) ? owner_data.paypal_secret_key : "",


    // STRIPE_KEY: 'pk_test_51LfUb1Gmf7jvEtUNAjdx5BcsIHEOVNszqSGU9Su1qiff61FGMsYjft0jNrBnTVg1rvAjVHVwtA4i0fDoA6RYeh2p00jnv6kPyV',
    // STRIPE_SECRET: 'sk_test_51LfUb1Gmf7jvEtUN0wNfri5Oq7Ho92UCxj0uUSgkDvAVdnGbZvfp8QFJvVlP0IvMwEa7P3SpLnIi3NfKAL99Z34V00fQ9HDrcZ',
    // PAYPAL_CLIENT_ID: 'AWL_1J6jnkoctdN9q4nMq61zPH6gFhCVT-X_AvHUMfPW1p81snb5Oxf9tItwbHgaEocoOxo2nKLcKHUY',
    // PAYPAL_CLIENT_SECRET: 'EFNhV9w-nwpBWUZO8_wEhD1R_oGPY60Xg5d67o-9mEOur1qYHeowNYOXkrEsRItmtIoI_n2EPpBCmg2d'
}

const dev = {
    APP_URL: 'http://127.0.0.1:8000/',
    API_BASE_URL: 'http://127.0.0.1:8000/api/v1/',
    APP_NAME: 'Skip Hire Container Service',

    MAIN_OWNER_ID: (localStorage.getItem('MAIN_OWNER_ID') !== '' || localStorage.getItem('MAIN_OWNER_ID') !== undefined) ? localStorage.getItem('MAIN_OWNER_ID') : 0,

    API_LOGIN: 'user/login',
    API_LOGOUT: 'user/logout',
    API_REGISTER: 'user/register',
    API_FORGOT_PASSWORD: 'user/forgot-password',
    API_UPDATE_PROFILE: 'user/profile-update',
    API_CHANGE_PASSWORD: 'user/change-password',
    API_CONTACT_OWNER: 'user/contact-owner',
    API_CONTACT_OWNER_FOR_WEB: 'user/contact-owner-for-web',
    API_ADDRESS_LIST: 'user/address-list',
    API_ADD_ADDRESS: 'user/add-address',
    API_UPDATE_ADDRESS: 'user/update-address',
    API_GET_ORDER_NOTES_DOCUMENTS: 'user/get-order-notes-documents',
    API_GET_ORDER_LIST: 'user/get-order-list',
    API_GARBAGE_TYPE_LIST: 'user/garbage-type-list',
    API_ZIPCODE_CHECK: 'user/zipcode-check',
    API_CONTAINER_LIST: 'user/container-list',
    API_GET_BLOGS_OFFER: 'user/get-blogs-offer',
    API_GET_BLOG_DETAIL: 'user/get-blogs-detail',
    API_MAKE_PICKUP_REQUEST: 'user/make-pickup-request',
    API_MAKE_ORDER_PAYMENT: 'user/make-order-payment',
    API_MAKE_PAYPAL_PAYMENT: 'user/make-paypal-payment',

    //Material Module API
    API_MATERIAL_CATEGORY_LIST: 'user/material/categories',
    API_MATERIAL_PRODUCT_LIST: 'user/material/products',
    API_MATERIAL_MAKE_STRIPE_PAYMENT: 'user/material/make-order-payment',
    API_MATERIAL_MAKE_PAYPAL_PAYMENT: 'user/material/make-order-paypal-payment',
    API_MATERIAL_GET_ORDER_HISTORY: 'user/material/get-order-list',
    API_MATERIAL_MAKE_PICKUP_REQUEST: 'user/material/make-pickup-request',
    API_MATERIAL_GET_ORDER_NOTES_DOCUMENT: 'user/material/get-material-order-notes-documents',
    API_MATERIAL_GET_SINGLE_PRODUCT: 'user/material/get-single-product',

    API_PAYMENT_INTENT: 'user/create-payment-intent',

    API_APPLY_COUPON: 'user/apply-coupon',
    API_REMOVE_COUPON: 'user/remove-coupon',

    STRIPE_KEY: (owner_data) ? owner_data.stripe_key : "",
    STRIPE_SECRET: (owner_data) ? owner_data.stripe_secret_key : "",
    PAYPAL_CLIENT_ID: (owner_data) ? owner_data.paypal_key : "",
    PAYPAL_CLIENT_SECRET: (owner_data) ? owner_data.paypal_secret_key : "",

    // STRIPE_KEY: 'pk_test_51LfUb1Gmf7jvEtUNAjdx5BcsIHEOVNszqSGU9Su1qiff61FGMsYjft0jNrBnTVg1rvAjVHVwtA4i0fDoA6RYeh2p00jnv6kPyV',
    // STRIPE_SECRET: 'sk_test_51LfUb1Gmf7jvEtUN0wNfri5Oq7Ho92UCxj0uUSgkDvAVdnGbZvfp8QFJvVlP0IvMwEa7P3SpLnIi3NfKAL99Z34V00fQ9HDrcZ',
    // PAYPAL_CLIENT_ID: 'AWL_1J6jnkoctdN9q4nMq61zPH6gFhCVT-X_AvHUMfPW1p81snb5Oxf9tItwbHgaEocoOxo2nKLcKHUY',
    // PAYPAL_CLIENT_SECRET: 'EFNhV9w-nwpBWUZO8_wEhD1R_oGPY60Xg5d67o-9mEOur1qYHeowNYOXkrEsRItmtIoI_n2EPpBCmg2d'
}

const dev_server = {
    APP_URL: 'https://dev.recycling-network.de/backend/',
    API_BASE_URL: 'https://dev.recycling-network.de/backend/api/v1/',
    APP_NAME: 'Skip Hire Container Service',
    
    MAIN_OWNER_ID: (localStorage.getItem('MAIN_OWNER_ID')!=='' || localStorage.getItem('MAIN_OWNER_ID')!==undefined) ? localStorage.getItem('MAIN_OWNER_ID') : 0,

    API_LOGIN: 'user/login',
    API_LOGOUT: 'user/logout',
    API_REGISTER: 'user/register',
    API_FORGOT_PASSWORD: 'user/forgot-password',
    API_UPDATE_PROFILE: 'user/profile-update',
    API_CHANGE_PASSWORD: 'user/change-password',
    API_CONTACT_OWNER: 'user/contact-owner',
    API_CONTACT_OWNER_FOR_WEB: 'user/contact-owner-for-web',
    API_ADDRESS_LIST: 'user/address-list',
    API_ADD_ADDRESS: 'user/add-address',
    API_UPDATE_ADDRESS: 'user/update-address',
    API_GET_ORDER_NOTES_DOCUMENTS: 'user/get-order-notes-documents',
    API_GET_ORDER_LIST: 'user/get-order-list',
    API_GARBAGE_TYPE_LIST: 'user/garbage-type-list',
    API_ZIPCODE_CHECK: 'user/zipcode-check',
    API_CONTAINER_LIST: 'user/container-list',
    API_GET_BLOGS_OFFER: 'user/get-blogs-offer',
    API_GET_BLOG_DETAIL: 'user/get-blogs-detail',
    API_MAKE_PICKUP_REQUEST: 'user/make-pickup-request',
    API_MAKE_ORDER_PAYMENT: 'user/make-order-payment',
    API_MAKE_PAYPAL_PAYMENT: 'user/make-paypal-payment',

    //Material Module API
    API_MATERIAL_CATEGORY_LIST: 'user/material/categories',
    API_MATERIAL_PRODUCT_LIST: 'user/material/products',
    API_MATERIAL_MAKE_STRIPE_PAYMENT: 'user/material/make-order-payment',
    API_MATERIAL_MAKE_PAYPAL_PAYMENT: 'user/material/make-order-paypal-payment',
    API_MATERIAL_GET_ORDER_HISTORY: 'user/material/get-order-list',
    API_MATERIAL_MAKE_PICKUP_REQUEST: 'user/material/make-pickup-request',
    API_MATERIAL_GET_ORDER_NOTES_DOCUMENT: 'user/material/get-material-order-notes-documents',
    API_MATERIAL_GET_SINGLE_PRODUCT: 'user/material/get-single-product',

    API_PAYMENT_INTENT: 'user/create-payment-intent',

    API_APPLY_COUPON: 'user/apply-coupon',
    API_REMOVE_COUPON: 'user/remove-coupon',

    STRIPE_KEY: (owner_data) ? owner_data.stripe_key : "",
    STRIPE_SECRET: (owner_data) ? owner_data.stripe_secret_key : "",
    PAYPAL_CLIENT_ID: (owner_data) ? owner_data.paypal_key : "",
    PAYPAL_CLIENT_SECRET: (owner_data) ? owner_data.paypal_secret_key : "",
    
    // STRIPE_KEY: 'pk_test_51LfUb1Gmf7jvEtUNAjdx5BcsIHEOVNszqSGU9Su1qiff61FGMsYjft0jNrBnTVg1rvAjVHVwtA4i0fDoA6RYeh2p00jnv6kPyV',
    // STRIPE_SECRET: 'sk_test_51LfUb1Gmf7jvEtUN0wNfri5Oq7Ho92UCxj0uUSgkDvAVdnGbZvfp8QFJvVlP0IvMwEa7P3SpLnIi3NfKAL99Z34V00fQ9HDrcZ',
    // PAYPAL_CLIENT_ID: 'AWL_1J6jnkoctdN9q4nMq61zPH6gFhCVT-X_AvHUMfPW1p81snb5Oxf9tItwbHgaEocoOxo2nKLcKHUY',
    // PAYPAL_CLIENT_SECRET: 'EFNhV9w-nwpBWUZO8_wEhD1R_oGPY60Xg5d67o-9mEOur1qYHeowNYOXkrEsRItmtIoI_n2EPpBCmg2d'
}

const staging_server = {
    APP_URL: 'https://staging.recycling-network.de/backend/',
    API_BASE_URL: 'https://staging.recycling-network.de/backend/api/v1/',
    APP_NAME: 'Skip Hire Container Service',
    
    MAIN_OWNER_ID: (localStorage.getItem('MAIN_OWNER_ID')!=='' || localStorage.getItem('MAIN_OWNER_ID')!==undefined) ? localStorage.getItem('MAIN_OWNER_ID') : 0,

    API_LOGIN: 'user/login',
    API_LOGOUT: 'user/logout',
    API_REGISTER: 'user/register',
    API_FORGOT_PASSWORD: 'user/forgot-password',
    API_UPDATE_PROFILE: 'user/profile-update',
    API_CHANGE_PASSWORD: 'user/change-password',
    API_CONTACT_OWNER: 'user/contact-owner',
    API_CONTACT_OWNER_FOR_WEB: 'user/contact-owner-for-web',
    API_ADDRESS_LIST: 'user/address-list',
    API_ADD_ADDRESS: 'user/add-address',
    API_UPDATE_ADDRESS: 'user/update-address',
    API_GET_ORDER_NOTES_DOCUMENTS: 'user/get-order-notes-documents',
    API_GET_ORDER_LIST: 'user/get-order-list',
    API_GARBAGE_TYPE_LIST: 'user/garbage-type-list',
    API_ZIPCODE_CHECK: 'user/zipcode-check',
    API_CONTAINER_LIST: 'user/container-list',
    API_GET_BLOGS_OFFER: 'user/get-blogs-offer',
    API_GET_BLOG_DETAIL: 'user/get-blogs-detail',
    API_MAKE_PICKUP_REQUEST: 'user/make-pickup-request',
    API_MAKE_ORDER_PAYMENT: 'user/make-order-payment',
    API_MAKE_PAYPAL_PAYMENT: 'user/make-paypal-payment',

    //Material Module API
    API_MATERIAL_CATEGORY_LIST: 'user/material/categories',
    API_MATERIAL_PRODUCT_LIST: 'user/material/products',
    API_MATERIAL_MAKE_STRIPE_PAYMENT: 'user/material/make-order-payment',
    API_MATERIAL_MAKE_PAYPAL_PAYMENT: 'user/material/make-order-paypal-payment',
    API_MATERIAL_GET_ORDER_HISTORY: 'user/material/get-order-list',
    API_MATERIAL_MAKE_PICKUP_REQUEST: 'user/material/make-pickup-request',
    API_MATERIAL_GET_ORDER_NOTES_DOCUMENT: 'user/material/get-material-order-notes-documents',
    API_MATERIAL_GET_SINGLE_PRODUCT: 'user/material/get-single-product',
    
    API_PAYMENT_INTENT: 'user/create-payment-intent',

    API_APPLY_COUPON: 'user/apply-coupon',
    API_REMOVE_COUPON: 'user/remove-coupon',

    STRIPE_KEY: (owner_data) ? owner_data.stripe_key : "",
    STRIPE_SECRET: (owner_data) ? owner_data.stripe_secret_key : "",
    PAYPAL_CLIENT_ID: (owner_data) ? owner_data.paypal_key : "",
    PAYPAL_CLIENT_SECRET: (owner_data) ? owner_data.paypal_secret_key : "",
    
    // STRIPE_KEY: 'pk_test_51LfUb1Gmf7jvEtUNAjdx5BcsIHEOVNszqSGU9Su1qiff61FGMsYjft0jNrBnTVg1rvAjVHVwtA4i0fDoA6RYeh2p00jnv6kPyV',
    // STRIPE_SECRET: 'sk_test_51LfUb1Gmf7jvEtUN0wNfri5Oq7Ho92UCxj0uUSgkDvAVdnGbZvfp8QFJvVlP0IvMwEa7P3SpLnIi3NfKAL99Z34V00fQ9HDrcZ',
    // PAYPAL_CLIENT_ID: 'AWL_1J6jnkoctdN9q4nMq61zPH6gFhCVT-X_AvHUMfPW1p81snb5Oxf9tItwbHgaEocoOxo2nKLcKHUY',
    // PAYPAL_CLIENT_SECRET: 'EFNhV9w-nwpBWUZO8_wEhD1R_oGPY60Xg5d67o-9mEOur1qYHeowNYOXkrEsRItmtIoI_n2EPpBCmg2d'
}

let mainConfig

if(domain == 'dev.recycling-network.de'){
    mainConfig = dev_server;
}else if(domain == 'staging.recycling-network.de'){
    mainConfig = staging_server;
}else if(process.env.NODE_ENV === 'development'){
    mainConfig = dev;
}else{
    mainConfig = prod;
}

export const config = mainConfig;

if (data === "undefined") {
    getOwnerDomain(domain);
}

export function CheckAuth(status = 0) {
    if (status === 402 || status === 401) {
        window.location.href = '/logout';
    }
}

export function getOwnerDomain(domain) {

    axios.post(config.API_BASE_URL + 'user/owner-details-by-domain', { domain: domain })
        .then((data) => {
            if (data.status === 200) {
                localStorage.setItem('owner_data', JSON.stringify(data.data.data));
                localStorage.setItem('MAIN_OWNER_ID', data.data.data.id);
                localStorage.setItem('vat_tax', data.data.data.vat_tax);
            } else {

            }
        }).catch((err) => {
        });
}

export function getApiToken() {
    let authUser = JSON.parse(localStorage.getItem('skipgireusertoken'));
    if (authUser !== null) {
        return authUser.api_token;
    } else {
        let GuestAuthUser = JSON.parse(localStorage.getItem('skiphire_guest_user'));
        if (GuestAuthUser !== null) {
            return GuestAuthUser.api_token;
        } else {
            return null;
        }
    }
}

export function ownerDomains() {
    return [
        {id: 791, name: 'Boho'},
        {id: 1179, name: 'Preis'},
    ];
}









