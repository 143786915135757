import React, { useEffect,useState } from 'react'
import SidebarHotline from '../Components/SidebarHotline'
import FrontendLayout from '../Layout/FrontendLayout'
import {Elements} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { config } from "./../constans";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { makePayPalPayment } from './../Store/Slices/PaymentSlice'
import StripeCheckoutForm from "./../Components/StripeCheckoutForm"
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import YellowHeader from '../Components/YellowHeader';
import GiropayCheckout from '../Components/GiropayCheckout';
import SofortCheckout from '../Components/SofortCheckout';
import PaymentOnSite from '../Components/PaymentOnSite';
import { sendCouponCode, removeCouponCode } from '../Store/Slices/CouponCodeSlice';
import { numberFormat } from '../Components/NumberFormat';
import { storeUser } from '../Store/Slices/UserAuthSlice';
import LoadingSpinner from '../Components/LoadingSpinner';
import StatusMessages, { useMessages } from '../Components/StatusMessages';
import { Link } from 'react-router-dom';

export default function SelectPaymentMethod(props) {

    // Constans
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [CouponBtnLoading, setCouponBtnLoading] = useState(false);
    const [FinalDisplayPrice, setFinalDisplayPrice] = useState(0.00);
    const [UserDiscount, setUserDiscount] = useState(0.00);
    const [vatTax, setVatTax] = useState(0.00);
    const [CouponDiscount, setCouponDiscount] = useState(0.00);
    const [messages, addMessage] = useMessages();
    const [PayPalOrderID, addPaypalOrderID] = useState("");
    const [loadingPaypalBtn, setLoadingPaypalBtn] = useState(false);
    const [paypal_payment_success, setPaypalSuccess] = useState(false);
    
   
    let user = useSelector(storeUser);
    let tempUser = (localStorage.getItem('skiphire_guest_user')!==null) ? JSON.parse(localStorage.getItem('skiphire_guest_user')) : null;

    const owner_data = (localStorage.getItem('owner_data') !== '' && localStorage.getItem('owner_data')!==undefined && localStorage.getItem('owner_data')!=='undefined') ? JSON.parse(localStorage.getItem('owner_data')) : '';

    let payment_status = (owner_data) ? owner_data.payment_status : ""

    let isrecorder = localStorage.getItem('is_reorder');
    let order_id = localStorage.getItem('order_id');

    const selectedAddress = JSON.parse(localStorage.getItem('selected_address'));
    const appoinmentForm = JSON.parse(localStorage.getItem('appoinmentForm'));
    const container_id = JSON.parse(localStorage.getItem('container_id'));
    const selectedZIPCODE = JSON.parse(localStorage.getItem('selectedZIPCODE'));
    
    const [coupondata, setCouponData] = useState((localStorage.getItem('coupon_data') !== '' || localStorage.getItem('coupon_data')!==undefined) ? JSON.parse(localStorage.getItem('coupon_data')) : '');

    const container_price = localStorage.getItem('container_price');

    let user_data = JSON.parse(localStorage.getItem('skipgireusertoken'));

    let user_discount = (user_data) ? user_data.discount : tempUser.discount;
    let user_vat_tax = (localStorage.getItem('vat_tax') !== '' || localStorage.getItem('vat_tax')!==undefined) ? localStorage.getItem('vat_tax') : 0;  

    const [couponForm, setCouponForm] = useState({
        owner_id: localStorage.getItem('MAIN_OWNER_ID'),
        coupon_code: '',
        user_id: (user_data) ? user_data.id : tempUser.id,
    });

    const handleInputChange = (e) => {
        setCouponForm({ ...couponForm, coupon_code: e.target.value });
    }

    const handleCouponBtnClick = async () => {
        setCouponBtnLoading(true);
        dispatch(sendCouponCode(couponForm)).then((data) => {
            if (data.status === 200) {
                toast.success(data.message);
                setCouponData(data.data);

                setCouponForm({ ...couponForm, coupon_code: '' });
            } else {
                toast.error(data.message);
            }
            setCouponBtnLoading(false);
        })
    }

    const handleRemoveCouponClick = (coupon_code) => {
        const param = ({
            coupon_code: coupon_code,
            user_id: user.id
        })
        dispatch(removeCouponCode(param)).then((data) => {
            if (data.status === 200) {
                setCouponData(''); 
                localStorage.removeItem('coupon_data');
                toast.success(data.message);
            } else {
                toast.error(data.message);
            }
        })
    }

    var stripePromise;
    // Stripe Setup
    if(config.STRIPE_KEY!=""){
        stripePromise = loadStripe(config.STRIPE_KEY);
    }else if(owner_data && owner_data.stripe_key){
        stripePromise = loadStripe(owner_data.stripe_key);
    }else{
        console.log('Keys not loaded properly!');
        stripePromise = loadStripe(owner_data.stripe_key);
    }
    // const [paymentIntent, setPaymentIntent] = useState('');
    
    // let StripeOptions = {
    //     clientSecret: paymentIntent,
    //     locale:'de'
    // };
    
    useEffect(() => {
        window.scrollTo(0, 0);
        calculateFinalPrice();
    }, [coupondata]);

    const calculateFinalPrice = () => {
        
        if(Object.keys(user).length > 0 || (tempUser!==null && Object.keys(tempUser).length)) {

            var str_discount = calCulateDiscount();
            var str_coupon_percentage = calCulateCouponAmount();
            
            var str_price = container_price;
            
            if (str_price && str_price.indexOf(',') > -1) { 
                str_price = str_price.replace(',', '.');
            }
            // if (str_price.includes(',')) {
            //     str_price = str_price.replace(',', '.');
            // }

            var price = parseFloat(str_price);

            var final_price = price - parseFloat(str_discount) - parseFloat(str_coupon_percentage) + calculateVatTax();

            final_price = final_price.toFixed(2);
            var str_final_price = '' + final_price;
            
            if (str_final_price && str_final_price.includes('.')) {
                str_final_price = str_final_price.replace('.', ',');
            }
            
            setFinalDisplayPrice(str_final_price);

            if (str_final_price && str_final_price.indexOf(',') > -1) { 
                str_final_price = str_final_price.replace(',', '.');
            }
            localStorage.setItem('final_order_price', str_final_price*100);
            return str_final_price;

        } else {
            return 0.0;
        }
    }

    const calCulateDiscount = () => {
        let str_discount = user_discount;
        
        let str_price = container_price;
        if (str_discount !== '0') {

            if (str_discount && str_discount.indexOf(',') > -1) { 
                str_discount = str_discount.replace(',', '.');
            }

            if (str_price && str_price.indexOf(',') > -1) { 
                str_price = str_price.replace(',', '.');
            }

            let discount_amount = (parseFloat(str_price) * parseFloat(str_discount)) / 100;

            setUserDiscount(discount_amount);
            return discount_amount;

        } else {
            return 0.0;
        }
    }

    const calCulateCouponAmount = () => {

        let str_coupon_perent = (coupondata) ? coupondata.coupon_discount : '0';
        let str_container_price = container_price;
        
        if (str_coupon_perent !== '0') {

            if (str_container_price && str_container_price.indexOf(',') > -1) { 
                str_container_price = str_container_price.replace(',', '.');
            }

            let price = parseFloat(str_container_price);
            let couponDiscountInPercent = parseFloat(str_coupon_perent);

            let discount = calCulateDiscount();
            let discount_amount = ((price-discount) * couponDiscountInPercent) / 100;

            setCouponDiscount(discount_amount);
            return discount_amount;

      } else {
        return 0.0;
      }
    }

    const calculateVatTax = () => {
        let user_discount = calCulateDiscount();
        let price_container = container_price;

        // if (price_container.includes(',')) {
        //   price_container = price_container.replace(',', '.');
        // }

        if (price_container && price_container.indexOf(',') > -1) { 
            price_container = price_container.replace(',', '.');
        }
        
        let price = parseFloat(price_container);
        let final_price = price - parseFloat(user_discount);

        let str_coupon_percent = calCulateCouponAmount()
        
        final_price = final_price - parseFloat(str_coupon_percent);

        let str_vat_percent = user_vat_tax;

        if (str_vat_percent && str_vat_percent.indexOf(',') > -1) { 
            str_vat_percent = str_vat_percent.replace(',', '.');
        }

        let vatTax = (final_price * parseFloat(str_vat_percent)) / 100;
        setVatTax(vatTax);
        return vatTax;
    }
    
    // async function getScript() {
    //     const stripeParams = {amount:2000, currency:'EUR', 'payment_method_types[]':['card','sepa_debit', 'giropay']}
    //     await axios.post('https://api.stripe.com/v1/payment_intents', stripeParams, {
    //         headers: {
    //             Authorization: 'Bearer '+config.STRIPE_SECRET,
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         }
    //     })
    //     .then(({ data }) => {
    //         setPaymentIntent(data.client_secret);
    //     })
    //     .catch(function (error) {
            
    //     });
    // }
    // Paypal Setup
    let PaypalOptions = {
        "client-id": config.PAYPAL_CLIENT_ID,
        'currency': 'EUR',
        'locale': 'de_DE'
    };
    
    const createOrder = (data, actions) => {
        setLoadingPaypalBtn(true);
        let payAmount = FinalDisplayPrice;
        if (payAmount && payAmount.indexOf(',') > -1) { 
            payAmount = payAmount.replace(',', '.');
        }
        
        return actions.order.create({
            purchase_units: [{
                description: "Container - ".container_id,
                amount: {
                    currency_code: "EUR",
                    value: payAmount,
                },
              },
            ],
            // not needed if a shipping address is actually needed
            application_context: {
                shipping_preference: "NO_SHIPPING",
            },
        }).then((orderID) => {
            addPaypalOrderID(orderID);
            return orderID;
        });
    };
    
    const onApprove = (data, actions) => {
        let paypalPayload = {
            owner_id: localStorage.getItem('MAIN_OWNER_ID'),
            address_id: selectedAddress.id,
            container_id: container_id,
            garbage_id: localStorage.getItem('garbage_id'),
            pickup_date: Math.round(+new Date(appoinmentForm.pickup_on) / 1000),
            pickup_time: appoinmentForm.delivery_time,
            delivery_date: Math.round(+new Date(appoinmentForm.delivery_on)/1000),
            delivery_time: appoinmentForm.delivery_time,
            pincode: selectedZIPCODE[0].name,
            payment_order_id: data.orderID,
            order_type: 'new_order',
            coupon_id: (coupondata) ? coupondata.id : '0',
            placement_option: appoinmentForm.placement_option,
        }

        if (isrecorder === '1' && order_id >= 0) {
            paypalPayload = {...paypalPayload, order_type:'swap_order', order_id:order_id}
        }

        dispatch(makePayPalPayment(paypalPayload)).then((paypalResponse) => {
            setLoadingPaypalBtn(true);
            if (paypalResponse.status === 200) {
                toast.success(paypalResponse.message);
                localStorage.removeItem('appoinmentForm');
                localStorage.removeItem('container_id');
                //localStorage.removeItem('container_price');
                localStorage.removeItem('selectedZIPCODE');
                localStorage.removeItem('selected_address');
                localStorage.removeItem('is_reorder');
                localStorage.removeItem('order_id');
                localStorage.removeItem('garbage_id');
                localStorage.removeItem('new_reorder_container_id');
                localStorage.removeItem('skiphire_guest_user');
                localStorage.removeItem('coupon_data');
                setLoadingPaypalBtn(false);
                setPaypalSuccess(true);
            } else {
                toast.error(paypalResponse.message);
            }
            setLoadingPaypalBtn(false);
        })
    };
    
    const onError = (data, actions) => {
        setLoadingPaypalBtn(false);
        addMessage('');
        addMessage("An Error occured with your payment");
    };

    return (
        <FrontendLayout>
            <section className="container">
                <div className="row mt-5">
                    <YellowHeader title={ t('Select Payment') } />
                    <SidebarHotline />
                    
                    <div className="col-md-9 col-lg-9 col-sm-12 col-sx-12 mt-5 order-md-2">
                        <div className="col-md-8 col-lg-8 col-sm-8 col-sx-12">
                            <table className="table">
                                <thead>
                                    <tr>
                                    <th scope="col">{t('Designation')}</th>
                                    <th scope="col">{t('In total')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{localStorage.getItem('container_size')}m<sup>3</sup> {localStorage.getItem('container_name')}</td>
                                        <td>{ container_price } €</td>
                                    </tr>
                                    
                                    { (() => {
                                        if(user_discount && user_discount !== "0") {
                                            return (
                                                <tr>
                                                    <td>{t('User Discount')} ({user_discount}%)</td>
                                                    <td>-{ numberFormat(UserDiscount) }</td>
                                                </tr>
                                            )
                                        }
                                    }) ()}

                                    { (() => {
                                        if(coupondata === null) {
                                            return('')
                                        } else if(coupondata && coupondata !== "") {
                                            return (
                                            <tr>
                                                <td>{t('Coupon Code')} ({coupondata.coupon_code})</td>
                                                <td>-{ numberFormat(CouponDiscount) }  &nbsp;
                                                <button 
                                                    onClick={() => handleRemoveCouponClick(coupondata.coupon_code)}
                                                    className='btn-sm btn btn-danger' ><i className='fa fa-times-circle'></i> {t('Remove')}</button></td>
                                            </tr>
                                            )
                                        } else {
                                            return ('');
                                        }
                                    }) ()}

                                    <tr>
                                        <td>{t('Vat Tax')} ({user_vat_tax}%)</td>
                                        <td>{ numberFormat(vatTax) }</td>
                                    </tr>

                                    <tr>
                                        <th>{t('Total')}</th>
                                        <td>{ FinalDisplayPrice } €</td>
                                    </tr>
                                </tbody>
                            </table>

                            <form className="card p-2">
                                <div className="input-group">
                                    <input type="text" className="form-control" id="coupon_code" placeholder="Promo code" value={couponForm.coupon_code || ""} onChange={handleInputChange}/>
                                    <button disabled={CouponBtnLoading} onClick={handleCouponBtnClick} className="col-md-4 btn btn-secondary">{CouponBtnLoading && <LoadingSpinner />} { t('Apply Coupon') }</button>
                                </div>
                            </form>
                        </div>
                        <br/>
                        <nav>
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">

                            {payment_status.length > 0 && payment_status.map((data, index) => (
                                <>
                                    { (() => {
                                        if(data.payment_type === "credit_card" && data.status === "active") {
                                            return (
                                                <button key={index} className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">{t('Stripe')}</button>
                                            )
                                        } else if (data.payment_type === "paypal" && data.status === "active") {
                                            return (
                                                <button key={data.id} className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">{ t('Paypal') }</button>
                                            )
                                        } else if (data.payment_type === "giropay" && data.status === "active") {
                                            return (
                                                <button key={data.id} className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-giropay" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">{ t('GiroPay') }</button>
                                            )
                                        } else if (data.payment_type === "sofort" && data.status === "active") {
                                            return (
                                                <button key={data.id} className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-sofort" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">{ t('Sofort') }</button>
                                            )
                                        } else if (data.payment_type === "payment_on_site" && data.status === "active") {
                                            localStorage.setItem("payment_on_site_desc", data.description);
                                            return (
                                                <button key={data.id} className="nav-link" id="nav-payment-on-site-tab" data-bs-toggle="tab" data-bs-target="#nav-payment-on-site" type="button" role="tab" aria-controls="nav-payment-on-site" aria-selected="false">{ t('Payment On Site') }</button>
                                            )
                                        }  
                                    }) ()}
                                </>     
                            ))}
                            </div>
                            
                            
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            {/* Stripe Credit Card */}
                            <div className="tab-pane fade p-3 show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <Elements stripe={stripePromise}>
                                    <StripeCheckoutForm/>
                                </Elements>
                            </div>
                                
                            {/* Paypal Express Checkout */}
                            <div className="tab-pane fade p-3" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                { (() => {
                                    if(loadingPaypalBtn === true) {
                                        return (
                                            <div className="bg-light rounded">
                                            <h5> <div className="spinner-border me-2" style={{height:'25px', width:'25px'}} role="status"></div> {t("Please Wait we are processing your order don't press back or refresh Page")}.....</h5>
                                            </div>
                                        )
                                    } else if(PayPalOrderID !== "" && loadingPaypalBtn === false) {
                                        return (
                                            <div className="bg-light rounded">
                                                <h1>{t('Thank You')}</h1>
                                                <p className="lead"> {t('Thank you for your Order')} </p>
                                                <p><b>{t('Paypal Order ID')}:</b> {PayPalOrderID}</p>
                                                <p><b>{t('Payment Method')}:</b> {t('Paypal')}</p>
                                                <Link className="btn btn-lg btn-primary" to="/orders" role="button">{t('View Orders')} »</Link>
                                            </div>
                                        )
                                    }
                                    
                                }) ()}
                                <br/>
                                { paypal_payment_success === false &&
                                    <PayPalScriptProvider options={PaypalOptions}>
                                        <PayPalButtons
                                            style={{ layout: "horizontal" }}
                                            createOrder={createOrder}
                                            onApprove={onApprove}
                                            onError={onError}
                                        />
                                    </PayPalScriptProvider>
                                }
                                
                            </div>

                            {/* Giropay */}
                            <div className="tab-pane fade p-3" id="nav-giropay" role="tabpanel" aria-labelledby="nav-giropay-tab">
                                <Elements stripe={stripePromise}>
                                    <GiropayCheckout />
                                </Elements>
                            </div>

                            {/* Sofort */}
                            <div className="tab-pane fade p-3" id="nav-sofort" role="tabpanel" aria-labelledby="nav-sofort-tab">
                                <Elements stripe={stripePromise}>
                                    <SofortCheckout/>
                                </Elements>
                            </div>

                            {/* Payment On Site */}
                            <div className="tab-pane fade p-3" id="nav-payment-on-site" role="tabpanel" aria-labelledby="nav-payment-on-site">
                                <PaymentOnSite />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </FrontendLayout>
    )
}
